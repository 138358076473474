.App {
  background-color: #012169;
}

.App-logo {
  height: 20vh;
  padding-top: 5%;
  max-width: 100%;
  object-fit: contain;
}


.App-main {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  color: white;
  max-width: 100%;
  background-color: #012169;
  padding-left: 3%;
  padding-right: 3%
}

.App-main-text {
  display: inline-block;
  flex-direction: column;
  text-align: left;
  font-size: 5vw;
  font-weight: 700;
  line-height: 2;
  padding-top: 5%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.App-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #012169;
  color: white;
  padding-left: 3%;
  padding-top: 4%;
  padding-bottom: 5%;
  font-size: 3vw;
  font-weight: 500;
}

.App-link {
  color: white;
}

.break-word {
  display: inline;
}

.quote-text {
  font-size: 5vw;
  font-weight: 700;
}

@media (min-width: 768px) {
  .App-main-text {
    font-size: 2rem;
    padding-right: 5%;
  }
  .App-footer {
    font-size: 1.5rem;
    padding-left: 3%;
  }
  .quote-text {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1030px) {
  .break-word {
    display: block;
  }
}
